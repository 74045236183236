import { Link } from "@reach/router";
import React, { Component } from "react";

import "./link.css";

class Button extends Component {
  render() {
    // check if absolute url
    if (this.props.to !== undefined) {
      if (
        this.props.to.startsWith("http") ||
        this.props.to.startsWith("https")
      ) {
        return (
          <a
            className={`link ${this.props.className || ""}`}
            href={this.props.to || "/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.children}
          </a>
        );
      }
    }
    // otherwise, return default
    return (
      <Link
        className={`link ${this.props.className || ""}`}
        to={this.props.to || "/"}
        href={this.props.to || "/"}
      >
        {this.props.children}
      </Link>
    );
  }
}
export default Button;
