import React, { Component } from "react";

import Link from "components/link/link";

import "./buttonLink.css";

class ButtonLink extends Component {
  render() {
    return (
      <Link className="buttonLink" to={this.props.to}>
        <button
          className={`button ${this.props.color || "gray"} ${this.props
            .selected === true && "selected"}`}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </button>
      </Link>
    );
  }
}
export default ButtonLink;
