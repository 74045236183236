import React, { Component } from "react";

import "./linkWithArrow.css";

export default class LinkWithArrow extends Component {
  render() {
    return (
      <a
        className="linkWithArrow"
        href={this.props.to || ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="label">
          <p>{this.props.label}</p>
        </div>
        <div className="arrowContainer">
          <p className="arrow">→</p>
        </div>
      </a>
    );
  }
}
