import React, { Component } from "react";

import "./linkWithIcon.css";

export default class LinkWithIcon extends Component {
  render() {
    return (
      <a className="linkWithIcon" href={this.props.to}>
        <div className="label">
          <p>{this.props.label}</p>
        </div>
        <div className="iconContainer">
          <img className="icon" src={this.props.icon} alt={this.props.alt} />
        </div>
      </a>
    );
  }
}

// <div className="svg-icon">
// <img src={EmailIcon} />
// </div>
