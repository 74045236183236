import React from "react";
import Client from "shopify-buy";

export const ShoppingCartContext = React.createContext();

class ShoppingCartProvider extends React.Component {
  constructor(props) {
    super(props);

    this.addLineItem = this.addLineItem.bind(this);
    this.updateLineItems = this.updateLineItems.bind(this);
    this.removeLineItem = this.removeLineItem.bind(this);
    this.syncLocalStorage = this.syncLocalStorage.bind(this);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      checkout: {},
      client: {},
      updateLineItems: this.updateLineItems
    };
  }

  addLineItem(lineItemToAdd, callback) {
    this.state.client.checkout
      .addLineItems(this.state.checkout.id, lineItemToAdd)
      .then(checkout => {
        this.syncLocalStorage(checkout);
        if (callback !== null && callback !== undefined) {
          callback();
        }
      });
  }

  updateLineItems(lineItemsToUpdate) {
    this.state.client.checkout
      .updateLineItems(this.state.checkout.id, lineItemsToUpdate)
      .then(checkout => {
        this.syncLocalStorage(checkout);
      });
  }

  removeLineItem(lineItemIdsToRemove) {
    // const lineItemIdsToRemove = [
    //     'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0Lzc4NTc5ODkzODQ='
    //   ];
    // Remove an item from the checkout
    this.state.client.checkout
      .removeLineItems(this.state.checkout.id, lineItemIdsToRemove)
      .then(checkout => {
        this.syncLocalStorage(checkout);
      });
  }

  syncLocalStorage(checkout) {
    // Update checkout in state
    this.setState(
      {
        checkout
      },
      // update checkout in local storage
      () => {
        window.localStorage.setItem(
          "checkout",
          JSON.stringify(this.state.checkout)
        );
      }
    );
  }

  componentDidMount() {
    // create Shopify JS Buy SDK Client
    const client = Client.buildClient({
      domain: "hecha-nyc.myshopify.com",
      storefrontAccessToken: "5de3dd1d0cdf3d9d05f77dc2683a9909"
    });
    // check if there is a checkout id stored in local storage
    if (typeof window !== "undefined") {
      if (window.localStorage.getItem("checkout") !== null) {
        const checkoutId = localStorage.getItem("checkoutId");
        client.checkout.fetch(checkoutId).then(checkout => {
          this.setState({ checkout, client });
        });
      } else {
        // otherwise, create new checkout
        client.checkout.create().then(checkout => {
          this.setState({ checkout, client }, () => {
            window.localStorage.setItem("checkoutId", this.state.checkout.id);
          });
        });
      }
    }
  }

  render() {
    return (
      <ShoppingCartContext.Provider
        value={{ ...this.state, addLineItem: this.addLineItem }}
      >
        {this.props.children}
      </ShoppingCartContext.Provider>
    );
  }
}

export default ShoppingCartProvider;
