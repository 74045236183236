import React, { Component } from "react";

import "./emailSubscribeForm.css";
import "./emailSubscribeFormSmallMobile.css";

export default class EmailSubscribeForm extends Component {
  render() {
    return (
      <div className="emailSubscribeForm">
        <div id="mc_embed_signup">
          <form
            action="https://hechanyc.us18.list-manage.com/subscribe/post?u=45ffa6ca8232b4a3a8c511844&amp;id=645d4d5362"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <div className="subscribeLabel">SUBSCRIBE</div>
              <input
                type="email"
                name="EMAIL"
                placeholder=""
                className="required email"
                id="mce-EMAIL"
              />
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_30f169a672211d7793db6b3e5_8ef7cba9fd"
                  tabIndex="-1"
                />
              </div>
              <div className="subscribeButton">
                <input
                  type="submit"
                  value="↗"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
