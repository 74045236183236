import React, { Component } from "react";
import ReactGA from "react-ga";
import { Root, Routes } from "react-static";

import "./reset.css";
import "./normalize.css";
import "./app.css";

import withTracker from "./components/googleAnalytics/withTracker";
import Navigation from "./components/navigation/navigation";
import Footer from "./components/footer/footer";

import ShoppingCartProvider from "./contexts/shoppingCartContext";

export default class App extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize("UA-125424057-1");
  }

  render() {
    return (
      <Root>
        <Routes>
          {({ routePath, getComponentForPath }) => {
            const Comp = getComponentForPath(routePath);
            // custom switch-based router for handling which
            // pages get wrapped in variations on navigation/footer
            ReactGA.pageview(routePath);
            // all routes get the 'default' wrapped with navigation and footer,
            // custom routes can abstain ( like the development /components page)
            switch (routePath) {
              case "/":
                //   determine if we need to absolutely anchor footer
                return (
                  <ShoppingCartProvider>
                    <div className="app">
                      <Navigation routePath={routePath} />
                      <Comp />
                      <Footer fixed />
                    </div>
                  </ShoppingCartProvider>
                );
              default:
                return (
                  <ShoppingCartProvider>
                    <div className="app">
                      <Navigation routePath={routePath} />
                      <Comp />
                      <Footer />
                    </div>
                  </ShoppingCartProvider>
                );
            }
          }}
        </Routes>
      </Root>
    );
  }
}
