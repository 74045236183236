// Node Modules
import React, { Component } from "react";

// Global Contexts
import { ShoppingCartContext } from "contexts/shoppingCartContext.js";

// Global Components
import Link from "components/link/link";
import ButtonLink from "components/buttonLink/buttonLink";
import ButtonGroup from "components/buttonGroup/buttonGroup";

// Local CSS
import "./navigation.css";
import "./navigation-mobile.css";

class Navigation extends Component {
  getLineItems(checkout) {
    if (checkout !== null && checkout.lineItems !== undefined) {
      return checkout.lineItems.length;
    }
    return 0;
  }

  render() {
    return (
      <div
        className={`navigation ${
          this.props.absolute === true ? "absolute" : ""
        }`}
      >
        <ButtonGroup>
          <ButtonLink
            color="green"
            to="/products"
            selected={this.props.routePath.indexOf("products") >= 0}
          >
            <p>Shop</p>
          </ButtonLink>
          <ButtonLink
            color="green"
            to="/projects"
            selected={this.props.routePath.indexOf("projects") >= 0}
          >
            <p>Projects</p>
          </ButtonLink>
          <ButtonLink
            color="green"
            to="/about"
            selected={this.props.routePath.indexOf("about") >= 0}
          >
            <p>About</p>
          </ButtonLink>
          <ShoppingCartContext.Consumer>
            {({ checkout }) => (
              <ButtonLink
                to="/cart"
                color={this.getLineItems(checkout) > 0 ? "orange" : ""}
              >
                <p>Cart ({this.getLineItems(checkout)})</p>
              </ButtonLink>
            )}
          </ShoppingCartContext.Consumer>
        </ButtonGroup>
      </div>
    );
  }
}
export default Navigation;
